import React from 'react';
import { Helmet } from "react-helmet";
import '../App.css';
import { withTranslation } from 'react-i18next';
import Credits from './Credits.js';
import ReactTooltip from "@huner2/react-tooltip";


function calculate(inputValue) {
  var rates = {
    am: 8 / 100, // 2024
    bundskat: 12.09 / 100, // 2024
    kommuneskat: 23.6 / 100, // 2024
    topskat: 15 / 100, // 2024
    topskatlowerlimit: 588900, // 2024
    groncheck: 0,
    personfradrag: 49700, // 2024
    beskeaftigelse: 10.65 / 100, // 2024
    beskeaftigelsemax: 45100, // 2024
    jobfradrag: 4.5 / 100, // 2024
    jobfradraglimit: 216100, // 2024
    jobfradragmax: 2800, // 2024
    skatteloft: 52.07 / 100, // 2024
    atp: 99 // 2024
  };

  var atp = function () {
    if (inputValue > 9999) {
      return rates.atp;
    } else {
      return 0;
    }
  };
  var yearpay = inputValue * 12;
  var paywithoutpension = inputValue - atp();
  var am = paywithoutpension * rates.am;

  var jobfradrag = function () {
    if (yearpay < rates.jobfradraglimit) {
      return 0;
    }
    if ((yearpay - rates.jobfradraglimit) * rates.jobfradrag > rates.jobfradragmax) {
      return rates.jobfradragmax;
    } else {
      return ((yearpay - rates.jobfradraglimit) * rates.jobfradrag)
    }
  };
  var beskeaftigelsesfradrag = function () {
    /*  */
    var result = yearpay * rates.beskeaftigelse;
    return (result < rates.beskeaftigelsemax) ? result : rates.beskeaftigelsemax;
  }
  var fradragkr = (rates.personfradrag + jobfradrag() + beskeaftigelsesfradrag()) / 12;

  var bundskatkr = ((paywithoutpension - am) > (rates.personfradrag / 12)) ? (paywithoutpension - am - (rates.personfradrag / 12)) * rates.bundskat : 0;
  var kommuneskatkr = ((paywithoutpension - am - fradragkr) > 0) ? (paywithoutpension - am - fradragkr) * rates.kommuneskat : 0;
  var topskatkr = function () {
    if ((paywithoutpension - am) * 12 > rates.topskatlowerlimit) {
      return (paywithoutpension - am - rates.topskatlowerlimit / 12) * rates.topskat;
    } else {
      return 0;
    }
  };
  var taxkr = kommuneskatkr + bundskatkr + topskatkr() + am;

  var payout = paywithoutpension - taxkr;
  var realtax = (inputValue > 0) ? taxkr / inputValue * 100 : 0;

  var result = {
    'aarslon': Number(yearpay).toLocaleString(),
    'atp': Number(atp()).toLocaleString('us'),
    'topskat': Number(Math.round(topskatkr())).toLocaleString(),
    'tax': Number(Math.round(taxkr)).toLocaleString(),
    'personfradrag': Number(Math.round((rates.personfradrag / 12))).toLocaleString(),
    'arbejdsfradrag': Number(Math.round((jobfradrag() + beskeaftigelsesfradrag()) / 12)).toLocaleString(),
    'realtax': Number(Math.round(realtax * 10) / 10).toLocaleString('us'),
    'payout': Number(Math.round(payout)).toLocaleString(),
  };

  return result
}

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ value: event.target.value });
  }

  renderMiniOutput(title, value, subtext, columnsclass, tooltip = false) {
    let infobox;
    let reacttooltip;
    if (tooltip) {
      infobox = <span className="tooltipelement" data-tip data-type="light" data-event="click" data-for={title}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg></span>
      reacttooltip = <ReactTooltip id={title} place="top" effect="solid" globalEventOff="click" className="tooltip">{tooltip}</ReactTooltip>
    }
    return (
      <div className={columnsclass}>
        <div className="box s-box xs-box bg-color1">
          <div className="box-label">{title} {infobox}</div>
          <div className="box-content">{value}<span>kr</span></div>
          <div className="box-subtext">{subtext}</div>
          {reacttooltip}
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    var result = calculate(this.state.value);
    return (
      <div className="udregner">
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <meta property="og:description" content={t('meta.description')} />
        </Helmet>
        <div className="row">
          <div className="box main-box bg-color1">
            <div className="inner-cont">
              <h1>{t('header.title')}</h1>
              <h3>{t('header.subtitle')}</h3>
              <div className="box-content">
                <div className="form-group">
                  <input type="number" placeholder="0" className="form-control pay-input" step="1000" value={this.state.input} onChange={this.handleInputChange} autoFocus />
                  <div className="currency">kr</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {this.renderMiniOutput(t('title.yearpay'), result.aarslon, t('per year'), "col-sm-6 col-md-6")}
          {this.renderMiniOutput(t('title.atp'), result.atp, t('per month'), "col-sm-6 col-md-6")}
          {this.renderMiniOutput(t('title.basededuction'), result.personfradrag, t('per month'), "col-sm-6 col-md-3")}
          {this.renderMiniOutput(t('title.jobdeductions'), result.arbejdsfradrag, t('per month'), "col-sm-6 col-md-3", t('title.jobdeductions-tooltip'))}
          {this.renderMiniOutput(t('title.toptax'), result.topskat, t('per month'), "col-sm-6 col-md-3")}
          {this.renderMiniOutput(t('title.tax'), result.tax, t('per month'), "col-sm-6 col-md-3", t('title.tax-tooltip'))}
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="box s-box skat-box bg-color1">
              <div className="box-label">{t('title.realtax')}</div>
              <div className="box-content">{result.realtax}<span>%</span></div>
            </div>
          </div>

          <div className="col-sm-8">
            <div className="box s-box result-box bg-color1">
              <div className="box-label">{t('title.payout')}</div>
              <div className="box-content">{result.payout}<span>kr</span></div>
              <div className="box-subtext">{t('per month')}</div>
            </div>
          </div>
        </div>
        <Credits />
        <div className="description">
          <div>{t('description1')}
            <br /><br />
            {t('description2')}</div>
          <br />
          <div className="note">
            {t('note')}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Calculator)
