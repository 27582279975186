import React from "react";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
import './LanguageSelector.css';


const LanguageSelector = () => {
  const changeLanguage = (event) => {
    i18next.changeLanguage(event.target.name);
  };

  return (
    <div className="language-selector">
      <NavLink onClick={changeLanguage} name="da" to="/">Dansk</NavLink>| 
      <NavLink onClick={changeLanguage} name="en" to="/en">English</NavLink>
    </div>
  );

};

export default LanguageSelector;