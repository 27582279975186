import React from 'react';
import './App.css';
import Calculator from './components/Calculator';
import LanguageSelect from './components/LanguageSelect';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="App">
          <div className="container">
            <LanguageSelect />
            <Routes>
                <Route path="/en" element={<Calculator language = "en" />} />
                <Route path="/" element={<Calculator language = "da" />} />
            </Routes>
          </div>
      </div>
    </Router>
  );
}

export default App;
