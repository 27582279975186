import { useTranslation } from "react-i18next";
import './Credits.css';

const Credits = () => {
    const { t } = useTranslation();

    return (
        <div className="credits">
            <div>
                <div className="by">{t('Created by')}</div>
                <div className="name"><a href="https://www.linkedin.com/in/christofferk/" target="_blank" rel="noreferrer">Christoffer Kaalund</a></div>
            </div>
            <div className="work">
                <div className="info">
                    <div className="text-medium">Til dagligt er jeg partner i <a href="https://www.velhold.dk/">Velhold</a> Bygningsrådgivning, hvor vi laver:</div>
                </div>
                <div className="services-cont row">
                    <div className="col-sm-4">
                        <div className="service box">
                            <div className="service-name"><a href="https://www.velhold.dk/byggeteknisk-gennemgang">Byggeteknisk gennemgang ved huskøb</a></div>
                            <div className="service-desc">Så du er sikker på hvad du køber og undgår uheldige overraskelser.</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="service box">
                            <div className="service-name"><a href="https://www.velhold.dk/andelsvurdering">Andelsvurdering</a></div>
                            <div className="service-desc">Hvor vi prissætter din andelsbolig, dine forbedringer og løsøre samt evt. mangler.</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="service box">
                            <div className="service-name"><a href="https://www.velhold.dk/drift-og-vedligeholdelsesplan">Drift og vedligeholdelsesplan</a></div>
                            <div className="service-desc">Et dybdegående helbredstjek af jeres ejendom og 10 års plan for vedligehold.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Credits;